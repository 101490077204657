<template>
    <div id="newNotificationPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="geonotifications"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">

                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="geo_notif_createNewNotificationSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">

                                <div class="col-md-12">

                                    <div class="kt-portlet">

                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <path d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z" fill="#000000" />
                                                            <rect fill="#000000" opacity="0.3" x="10" y="16" width="4" height="4" rx="2" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("geo_notif_createNewNotification") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label for="newNotificationName">{{ $t("geo_notif_notificationName") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input v-model="vName" @input="$v.vName.$touch()" type="text" class="form-control" id="newNotificationName" :placeholder="$t('geo_notif_notificationNamePlaceHolder')" />
                                                                <div v-if="!$v.vName.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vName.minLen" class="invalid-feedback">
                                                                    {{ $t("error_minLengthMsg", [$v.vName.$params.minLen.min]) }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("geo_notif_notificationNameDetailInfo") }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("geo_notif_notificationSelectTriggerElement") }} <br /></label>
                                                            <div class="kt-radio-list" style=" margin-left: 10px; margin-top: 20px; ">
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newNotificationAnything" type="radio" v-model="vTrigger" name="triggerCondition" value="anything" checked /> {{ $t("geo_notif_notificationTriggerAnything") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                <div class="row" style=" margin-top: 20px; ">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newNotificationAssetsRadio" type="radio" v-model="vTrigger" name="triggerCondition" value="asset" /> {{ $t("geo_notif_notificationTriggerAsset") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="newNotificationAssetSelect2" multiple="multiple" class="form-control kt-select2" style="display:none;" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="!isCorrectAssetSelected" class="invalid-feedback assetSelect2Error">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newNotificationCategoriesRadio" type="radio" v-model="vTrigger" name="triggerCondition" value="assetCategory" /> {{ $t("geo_notif_notificationTriggerAssetCategory") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="newNotificationAssetCategorySelect2" multiple="multiple" class="form-control kt-select2" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="!isCorrectAssetCategorySelected" class="invalid-feedback assetCategorySelect2Error">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div :class="vCondition !== 'THRESHOLD' ? 'col-md-6' : 'col-md-4'">
                                                        <div class="form-group validated">
                                                            <label>{{ $t("geo_notif_notificationTriggerCondition") }} *</label>
                                                            <select id="newNotificationConditionSelect2" class="form-control kt-select2">
                                                                <!--option></option-->
                                                            </select>
                                                            <div v-if="!$v.vCondition.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("geo_notif_notificationConditionDetailInfo") }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="vCondition === 'THRESHOLD'" class="col-md-4">
                                                        <div class="form-group validated">
                                                            <label for="newNotificationGeofenceThreshold">{{ $t("geo_notif_notificationThreshold") }} *</label>
                                                            <input v-model="vThresholdNumber" id="newNotificationGeofenceThreshold" type="number" min=1 class="form-control" :placeholder='$t("geo_notif_thresholdMaxAssetsPlaceholder")' />
                                                            <div v-if="!$v.vThresholdNumber.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-if="!$v.vThresholdNumber.integer" class="invalid-feedback">
                                                                {{ $t("error_invalidThresholdNumberType") }}
                                                            </div>
                                                            <div v-if="$v.vThresholdNumber.required && $v.vThresholdNumber.integer && !$v.vThresholdNumber.correctValue" class="invalid-feedback">
                                                                {{ $t("error_invalidThresholdNumberMin") }}
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("geo_notif_input_thresholdMaxAssetDetailInfo") }}</span>
                                                        </div>
                                                    </div>
                                                    <div :class="vCondition !== 'THRESHOLD' ? 'col-md-6' : 'col-md-4'">
                                                        <div class="form-group validated">
                                                            <label>{{ $t("geofence_geofence") }} *</label>
                                                            <select id="newNotificationGeofenceSelect2" class="form-control kt-select2">
                                                                <!--option></option-->
                                                            </select>
                                                            <div v-if="!$v.vGeofence.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("geo_notif_notificationGeofenceDetailInfo") }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("geo_notif_notificationRoleToNotified") }} </label>
                                                            <div class="kt-radio-list" style="margin-left: 10px; margin-top: 20px;">
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newNotificationRoleAllRadio" type="radio" v-model="vWhoToAlert" name="role" value="all" checked /> {{ $t("geo_notif_notificationAllRoleToNotified") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                <div class="row" style=" margin-top: 20px; ">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newNotificationRoleAllSelectRadio" type="radio" v-model="vWhoToAlert" name="role" value="roles" /> {{ $t("geo_notif_notificationOnlyRoleToNotified") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="newNotificationRoleSelect2" class="form-control kt-select2" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="!isCorrectRoleSelected" class="invalid-feedback">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                                <div class="row" style=" margin-top: 20px; ">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newNotificationOnlyUsersSelectRadio" type="radio" v-model="vWhoToAlert" name="role" value="users"/> {{ $t("geo_notif_notificationOnlyUserToNotified") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="newNotificationOnlyUsersSelect2" class="form-control kt-select2" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="!isCorrectUserSelected" class="invalid-feedback">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("geo_notif_notificationMethodsFormTitle") }} *</label>
                                                            <div class="kt-checkbox-list" style=" margin-top: 20px; ">
                                                                <label v-for="notificationType in vNotificationTypesList" :key="notificationType.id" class="kt-checkbox kt-checkbox--brand kt-checkbox--bold">
                                                                    <input v-if="notificationType.name === 'WEBHOOK'" v-on:click="onCheckWebhook" :id="'notifTypeCheckbox_'+notificationType.name" type="checkbox" :value="notificationType.id" v-model="vNotificationTypes" />
                                                                    <input v-else-if="notificationType.name === 'IQM_SERVER' && isIQMServerDisabled()" :id="'notifTypeCheckbox_'+notificationType.name" type="checkbox" disabled/>
                                                                    <input v-else :id="'notifTypeCheckbox_'+notificationType.name" type="checkbox" :value="notificationType.id" v-model="vNotificationTypes" />{{ $t("geo_notif_"+notificationType.name) }}
                                                                    <a
                                                                        v-if="notificationType.name === 'MOBILE_PUSH_NOTIFICATION'"
                                                                        class="kt-link"
                                                                        id="linkWhatIsAndroidPushNotif"
                                                                        href="#"
                                                                        data-placement="right"
                                                                        data-toggle="kt-popover"
                                                                        data-html="true"
                                                                        title
                                                                        :data-content="$t('geo_notif_MobilePushExplain')"
                                                                        :data-original-title="$t('geo_notif_MOBILE_PUSH_NOTIFICATION')"
                                                                    >{{ $t("common_whatIsThis") }}</a>
                                                                    <a
                                                                        v-if="notificationType.name === 'WEBPUSH'"
                                                                        class="kt-link"
                                                                        id="linkWhatIsWebPushNotif"
                                                                        href="#"
                                                                        data-placement="right"
                                                                        data-toggle="kt-popover"
                                                                        data-html="true"
                                                                        title
                                                                        :data-content="$t('geo_notif_WebPushExplain')"
                                                                        :data-original-title="$t('geo_notif_WEBPUSH')"
                                                                    >{{ $t("common_whatIsThis") }}</a>
                                                                    <a
                                                                        v-if="notificationType.name === 'IQM_SERVER' && isIQMServerDisabled()"
                                                                        class="kt-link"
                                                                        id="linkWhatIsIqmServer"
                                                                        href="#"
                                                                        data-placement="right"
                                                                        data-toggle="kt-popover"
                                                                        data-html="true"
                                                                        title
                                                                        :data-content="$t('geo_notif_IQMServerExplain')"
                                                                        :data-original-title="$t('geo_notif_IQM_SERVER')"
                                                                    >{{ $t("common_whyIsOptionDisabled") }}</a>
                                                                    <button v-else-if="notificationType.name === 'IQM_SERVER' && isIQMNotDeclaredAssets()" type="button" class="btn btn-sm btn-danger marginL5 customSmButtonPadding" @click="onIQMAssetsWarningModal" id="buttonIQMAssetsWarning">
                                                                        <i class="fa fa-exclamation-triangle"></i>{{ $t("common_attentionRequired") }}
                                                                    </button>
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                            <div v-if="!$v.vNotificationTypes.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-if="vWebhookIsChecked" class="webhookBloc">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <div class="form-group validated">
                                                                            <label for="newWebhookUrl">{{ $t("geo_notif_webhookUrlFormTitle") }} *</label>
                                                                            <div class="input-group">
                                                                                <input v-model="vWebhookUrl" @input="$v.vWebhookUrl.$touch()" type="url" class="form-control" id="newWebhookUrl" :placeholder="$t('geo_notif_webhookUrlPlaceHolder')" />
                                                                                <div v-if="!$v.vWebhookUrl.required" class="invalid-feedback">
                                                                                    {{ $t("error_fieldIsRequired") }}
                                                                                </div>
                                                                                <div v-else-if="!$v.vWebhookUrl.url" class="invalid-feedback">
                                                                                    {{ $t("error_invalidUrlMsg") }}
                                                                                </div>
                                                                            </div>
                                                                            <span class="form-text text-muted"> {{ $t("geo_notif_webhookUrlDetailInfo") }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-6" style=" margin-top: -10px; ">
                                                                        <label>{{ $t("geo_notif_webhookHeaderFormTitle") }}</label>
                                                                        <div class="form-group validated">
                                                                            <div class="alert alert-secondary">
                                                                                <div class="form-group row">
                                                                                    <label class="col-lg-5 col-form-label">{{ $t("geo_notif_webhookHeaderKeyFormTitle") }}</label>
                                                                                    <label class="col-lg-5 col-form-label">{{ $t("geo_notif_webhookHeaderValueFormTitle") }}</label>
                                                                                    <div id="webhookHeaders" class="col-lg-12">
                                                                                        <div id="header1" class="row kt-margin-b-20">
                                                                                            <div class="input-group col-lg-5">
                                                                                                <input type="text" name="key" class="form-control" />
                                                                                            </div>
                                                                                            <div class="input-group col-lg-5">
                                                                                                <input type="text" name="value" class="form-control" />
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <a href="javascript:;" class="btn btn-danger btn-icon">
                                                                                                    <i class="la la-close"></i>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row kt-margin-t-20" style="margin-left:3px;">
                                                                                        <div class="col">
                                                                                            <div id="btAddNewHeader" v-on:click="onClickAddNewHeader" class="btn btn btn-brand marginTM30">
                                                                                                <span>
                                                                                                    <i class="la la-plus"></i>
                                                                                                    <span>{{ $t("geo_notif_webhookButtonAddHeader") }}</span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("geo_notif_notificationSeverityFormTitle") }} </label>
                                                            <div class="kt-radio-list" style="margin-left: 10px; margin-top: 20px;">
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newNotificationSeverityHighRadio" type="radio" v-model="vSeverity" name="severity" value="HIGH" checked /> {{ $t("geo_notif_notificationSeverityHigh") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                <div class="row" style=" margin-top: 20px; ">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newNotificationSeverityLowRadio" type="radio" v-model="vSeverity" name="severity" value="LOW" /> {{ $t("geo_notif_notificationSeverityLow") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("geo_notif_notificationPeriodFormTitle") }} </label>
                                                            <div class="kt-radio-list" style="margin-left: 10px; margin-top: 20px;">
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newNotificationDateAlwaysRadio" type="radio" v-model="vDateTime" name="datetime" value="always" v-on:click="disableDateSelect2" checked /> {{ $t("geo_notif_notificationPeriodAlways") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                <div class="row" style=" margin-top: 20px; ">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newNotificationDateSelectionRadio" type="radio" v-model="vDateTime" class="col-md-4" name="datetime" value="period" v-on:click="enableDateSelect2" /> {{ $t("geo_notif_notificationFromStartTime") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <div class="input-group date col-md-8" style=" margin-top: -9px; ">
                                                                        <div class="period input-group-prepend">
                                                                            <span> </span>
                                                                            <span class="input-group-text">
                                                                                <i class="la la-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="notificationStartTimeDatePicker" :placeholder="$t('geo_notif_startdatetimeplaceholder')" class="form-control" disabled />
                                                                        <label class="col-form-label" style=" margin-left: 10px; margin-right: 10px; ">{{ $t("geo_notif_notificationToEndTime") }}</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text">
                                                                                <i class="la la-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="notificationEndTimeDatePicker" :placeholder="$t('geo_notif_enddatetimeplaceholder')" class="form-control" disabled />
                                                                        <div v-if="!isCorrectEndDateTime" class="invalid-feedback" style=" margin-left: 4px; ">
                                                                            {{ $t("error_endDateIsNotCorrect") }}
                                                                        </div>
                                                                        <div v-if="!isDateSelected" class="invalid-feedback" style=" margin-left: 4px; ">
                                                                            {{ errorDateMessage }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("geo_notif_notificationStatusTitle") }} </label>
                                                            <div class="row" style=" margin-top: 20px; margin-left: 0px; ">
                                                                <div>
                                                                    <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                                                        <label>
                                                                            <input type="checkbox" checked="checked" id="newNotificationStatusCheckbox" v-model="statusActive" @change="newNotificationStatusChange">
                                                                            <span></span>
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                                <label style=" margin-top: -3px; margin-left: 5px; " class="col-form-label" id="newNotificationStatusLabel">{{ statusMessage }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions">
                                                    <div class="row">
                                                        <div class="col-lg-6 kt-align-right"></div>
                                                        <div class="col-lg-6 kt-align-right">
                                                            <label class="kt-checkbox kt-margin-r-20">
                                                                <input v-model="createAnother" type="checkbox" /> {{ $t("common_createAnother2") }}
                                                                <span></span>
                                                            </label>
                                                            <button id="newNotificationCreateButton" @click="onCreateButton" type="button" :disabled="$v.$invalid || !isCorrectAssetSelected || !isCorrectAssetCategorySelected || !isCorrectRoleSelected || !isCorrectUserSelected || !isCorrectEndDateTime || !isDateSelected" class="btn btn-brand kt-margin-r-5">
                                                                {{ $t("common_add") }}
                                                            </button>
                                                            <button id="newNotificationCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-iqmassetwarningmodal :textWarning="iqmAssetWarning"></app-iqmassetwarningmodal>
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength, url, requiredIf, integer, minValue } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import IQMAssetWarningModal from "../modals/iqmassetwarningmodal.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import i18n from "../../i18n";
export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            vName: null,
            vTrigger: this.$route.params.trigger || "anything",
            vCondition: this.$route.params.condition || null,
            vSeverity: "HIGH",
            vWhoToAlert: "all",
            vDateTime: "always",
            vAssets: [],
            vAssetCategories: this.$route.params.assetCategories || [],
            vRoles: [],
            vUsers: [],
            vGeofence: this.$route.params.geofence || null,
            vStartTime: null,
            vEndTime: null,
            vNotificationTypes: [],
            vNotificationTypesList: null,
            vWebhookIsChecked: false,
            vWebhookUrl: null,
            vThresholdNumber: this.$route.params.threshold || 1,
            statusMessage: i18n.t("geo_notif_notificationStatusActive"),
            statusActive: true,
            createAnother: false,
            isCorrectEndDateTime: true,
            isCorrectAssetSelected: true,
            isCorrectAssetCategorySelected: true,
            isCorrectRoleSelected: true,
            isCorrectUserSelected: true,
            isDateSelected: true,
            errorDateMessage: i18n.t('error_dateRequired'),
            iqmNotificationTypeId: null,
            iqmNotDeclaredAssetNames: null,
            iqmAssetWarning: ""
        };
    },
    created: function() {
        console.log("Component(newGeonotification)::created() - called");
        this.createAnother = this.createAnotherGeonotification;
        this.setCreateAnotherGeonotification(false);
        this.getSiteAssets(this.siteId);
        this.getSiteAssetCategories(this.siteId);
        this.getSiteRoles(this.siteId);
        this.getSiteGeofences(this.siteId);
        this.getApplicationNotificationTypes();
        this.getUsersOfSite(this.siteId);
    },
    mounted: function() {
        console.log(
            "Component(newGeonotification)::mounted() - Init metronic layout"
        );
        KTLayout.init();
        this.initStartDateTimePicker();
        this.initEndDateTimePicker();
        // For newGeonotification from addThresholdWidgetModal
        if (this.vAssetCategories.length > 0 && this.vTrigger === "assetCategory") {
            this.enableTriggerAssetCategorySelect2();
        }
        $('[data-toggle="kt-popover"]').popover({ trigger: "hover" });
    },
    destroyed: function() {
        console.log("Component(newGeonotification)::destroyed() - called");
        this.resetAssetsState();
        this.resetCategoriesState();
        this.resetSiteRolesState();
        this.resetGeofencesState();
        this.resetNotificationTypesState();
        this.resetSiteUsersState();
        this.destroyDatePicker();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vName: {
            required,
            minLen: minLength(2)
        },
        vWebhookUrl: {
            url,
            required: requiredIf(function() {
                return this.vWebhookIsChecked;
            })
        },
        vGeofence: {
            required
        },
        vCondition: {
            required
        },
        vNotificationTypes: {
            required
        },
        vThresholdNumber: {
            integer,
            minValue: 1,
            required: requiredIf(function() {
                return this.vCondition === "THRESHOLD"
            }),
            correctValue: value => value >= 1
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteAssets: function() {
            console.log("Component(newGeonotification)::watch(siteAssets) - called");
            if (this.siteAssets) {
                this.initAssetSelect2();
            }
        },

        user: function(user) {
            if (user) {
                console.log("Component(newGeonotification)::watch(user) called with : ", user);
                // Update language
                this.initAssetSelect2();
                this.initAssetCategorySelect2();
                this.initConditionSelect2();
                this.initGeofenceSelect2();
                this.initUsersSelect2();
                this.initRoleSelect2();
            }
        },

        vTrigger: function() {
            console.log("Component(newGeonotification)::watch(vTrigger) - called");
            if (this.vTrigger === "assetCategory") {
                this.enableTriggerAssetCategorySelect2();
            } else if (this.vTrigger === "asset") {
                this.enableTriggerAssetSelect2();
            } else if (this.vTrigger === "anything") {
                this.enableTriggerAnythingSelect2();
            } else {
                // Defense
                this.vTrigger = "anything";
                this.enableTriggerAnythingSelect2();
            }
        },

        siteAssetCategories: function() {
            console.log("Component(newGeonotification)::watch(siteAssetCategories) - called");
            if (this.siteAssetCategories) {
                this.initAssetCategorySelect2();
            }
        },

        siteRoles: function() {
            console.log("Component(newGeonotification)::watch(siteRoles) - called");
            if (this.siteRoles) {
                this.initRoleSelect2();
            }
        },

        usersOfSite: function() {
            console.log("Component(newGeonotification)::watch(usersOfSite) - called");
            if (this.usersOfSite) {
                this.initUsersSelect2();
            }
        },

        siteGeofences: function() {
            console.log("Component(newGeonotification)::watch(siteGeofences) - called");
            if (this.siteGeofences) {
                this.initGeofenceSelect2();
                this.initConditionSelect2();
            }
        },

        applicationNotificationTypes: function() {
            console.log("Component(newGeonotification)::watch(applicationNotificationTypes) - called");
            if (this.applicationNotificationTypes) {
                this.vNotificationTypesList = _.orderBy(
                    this.applicationNotificationTypes,
                    [notificationType => notificationType.name],
                    ["asc"]
                );
            }
            // Set the webhook in the last
            const findWebHook = _.find(this.vNotificationTypesList, {name: "WEBHOOK"});
            this.vNotificationTypesList = _.reject(this.vNotificationTypesList, {name: "WEBHOOK"});
            this.vNotificationTypesList.push(findWebHook);

            const iqmNotificationType = _.find(this.vNotificationTypesList, {name: "IQM_SERVER"});
            this.iqmNotificationTypeId = (iqmNotificationType ? iqmNotificationType.id : null);

            setTimeout(function(){
                $('[data-toggle="kt-popover"]').popover({ trigger: "hover" });
            }, 300);
        },
        // Set the threshold default value to 1
        vCondition: function(value) {
            if (value !== "THRESHOLD") {
                // Set the value to 1
                this.vThresholdNumber = 1;
            }
        },

        // Enable or disable select 2
        vWhoToAlert: function(value) {
            if (value === "all") {
                // Disable all select2 in who will be notified
                this.disableRoleSelect2();
                this.disableUserSelect2();
            } else if (value === "roles") {
                // Enable role select2 and disable user select 2
                this.enableRoleSelect2();
                this.disableUserSelect2();
            } else {
                // Enable user select 2 and disable role select 2
                this.enableUserSelect2();
                this.disableRoleSelect2();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "currentSite",
            "siteAssets",
            "siteAssetCategories",
            "siteRoles",
            "siteGeofences",
            "usersOfSite",
            "applicationNotificationTypes",
            "createAnotherGeonotification",
            "user"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getSiteAssets",
            "getUsersOfSite",
            "getSiteAssetCategories",
            "getSiteRoles",
            "getSiteGeofences",
            "getApplicationNotificationTypes",
            "createGeonotification",
            "setCreateAnotherGeonotification",
            "resetAssetsState",
            "resetCategoriesState",
            "resetSiteRolesState",
            "resetGeofencesState",
            "resetSiteUsersState",
            "resetNotificationTypesState"
        ]),

        initAssetSelect2() {
            var self = this;
            // Init Select2 data of asset
            let dataAssetSelect2 = [];
            for (let i = 0; i < this.siteAssets.length; i++) {
                let assetName = this.siteAssets[i].name;
                dataAssetSelect2.push({
                    id: this.siteAssets[i].id,
                    text: assetName,
                    assetName: assetName
                });
            }
            // Ascending sort order Select2 data by text
            dataAssetSelect2 = _.orderBy(
                dataAssetSelect2,
                [asset => asset.text.toLowerCase()],
                ["asc"]
            );
            // Init assets select2
            commonVueHelper.destroySelect2($("#newNotificationAssetSelect2"));

            $("#newNotificationAssetSelect2")
                .select2({
                    placeholder: i18n.t("geo_notif_notification_selectAsset"),
                    width: "30%",
                    data: dataAssetSelect2,
                    minimumResultsForSearch: 2,
                    containerCssClass: "geonotifSelect2"
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    // Store category index to retrieve associated subcategory
                    self.vAssets = $(this).val();
                    if (self.vAssets.length === 0) {
                        self.isCorrectAssetSelected = false;
                    } else {
                        self.isCorrectAssetSelected = true;
                    }
                    // Set list of selected asset names not declared in IQM
                    self.setIQMNotDeclaredAssetNames();
                });
        },

        initAssetCategorySelect2() {
            var self = this;
            // Init Select2 data of categories
            let dataCategorySelect2 = [];
            for (let i = 0; i < this.siteAssetCategories.length; i++) {
                let categoryName = this.siteAssetCategories[i].name;
                let isDefault = this.siteAssetCategories[i].isDefault;
                let image = this.siteAssetCategories[i].iconImg;
                let categoryNameTranslated;
                if (this.siteAssetCategories[i].isDefault) {
                    categoryNameTranslated = i18n.t(categoryName);
                } else {
                    categoryNameTranslated = categoryName;
                }
                dataCategorySelect2.push({
                    id: this.siteAssetCategories[i].id,
                    text: categoryNameTranslated,
                    categoryName: categoryName,
                    isDefault: isDefault,
                    image: image
                });
            }
            // Ascending sort order Select2 data by text
            dataCategorySelect2 = _.orderBy(
                dataCategorySelect2,
                [category => category.text.toLowerCase()],
                ["asc"]
            );
            // Init categories Select2
            commonVueHelper.destroySelect2(
                $("#newNotificationAssetCategorySelect2")
            );

            function formatCategory(categoryItem) {
                if (!categoryItem.id) return categoryItem.text;
                if (categoryItem.isDefault) {
                    return (
                        "<img class='flag' style='width:30px;margin-right:5px' src='/assets/categories/" +
                        categoryItem.categoryName +
                        "/Icon.png'/>" +
                        categoryItem.text
                    );
                } else {
                    return (
                        "<img class='iconPreviewCustomCat' style='background-image:url(" +
                        categoryItem.image +
                        ");margin-right:5px' />" +
                        categoryItem.text
                    );
                }
            }

            $("#newNotificationAssetCategorySelect2")
                .select2({
                    placeholder: i18n.t("geo_notif_notification_selectAssetCategory"),
                    width: "30%",
                    data: dataCategorySelect2,
                    minimumResultsForSearch: -1,
                    escapeMarkup: function(m) {
                        return m;
                    },
                    templateResult: formatCategory,
                    templateSelection: formatCategory,
                    containerCssClass: "geonotifSelect2"
                })
                .val(this.vAssetCategories)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vAssetCategories = $(this).val();
                    if (self.vAssetCategories.length === 0) {
                        self.isCorrectAssetCategorySelected = false;
                    } else {
                        self.isCorrectAssetCategorySelected = true;
                    }
                });
        },

        initRoleSelect2() {
            var self = this;
            // Init Select2 data of role
            let dataRoleSelect2 = [];
            for (let i = 0; i < this.siteRoles.length; i++) {
                var roleName =
                    this.siteRoles[i].isDefault === true
                        ? i18n.t("ROLE_" + this.siteRoles[i].name)
                        : this.siteRoles[i].name;
                dataRoleSelect2.push({
                    id: this.siteRoles[i].id,
                    text: roleName,
                    roleName: roleName
                });
            }
            // Ascending sort order Select2 data by text
            dataRoleSelect2 = _.orderBy(
                dataRoleSelect2,
                [role => role.text.toLowerCase()],
                ["asc"]
            );
            // Init roles select2
            commonVueHelper.destroySelect2($("#newNotificationRoleSelect2"));

            $("#newNotificationRoleSelect2")
                .select2({
                    placeholder: i18n.t("geo_notif_notification_selectRole"),
                    width: "40%",
                    data: dataRoleSelect2,
                    minimumResultsForSearch: -1,
                    multiple: true,
                    containerCssClass: "geonotifSelect2"
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vRoles = $(this).val();
                    if (self.vRoles.length == 0) {
                        self.isCorrectRoleSelected = false;
                    } else {
                        self.isCorrectRoleSelected = true;
                    }
                });
        },

        initUsersSelect2() {
            var self = this;
            // Init Select2 data of users
            let dataUsersSelect2 = [];
            for (let user of this.usersOfSite) {
                dataUsersSelect2.push({
                    id: user.user.id,
                    text: user.user.firstName + " " + user.user.lastName
                });
            }
            // Ascending sort order Select2 data by text
            dataUsersSelect2 = _.orderBy(dataUsersSelect2, ["text"], ["asc"]);
            // Init users select2
            commonVueHelper.destroySelect2($("#newNotificationOnlyUsersSelect2"));

            $("#newNotificationOnlyUsersSelect2")
                .select2({
                    placeholder: i18n.t("geo_notif_notification_selectUser"),
                    width: "40%",
                    data: dataUsersSelect2,
                    minimumResultsForSearch: -1,
                    multiple: true,
                    containerCssClass: "geonotifSelect2"
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vUsers = $(this).val();
                    if (self.vUsers.length == 0) {
                        self.isCorrectUserSelected = false;
                    } else {
                        self.isCorrectUserSelected = true;
                    }
                });
        },

        initGeofenceSelect2() {
            var self = this;
            // Init Select2 data of geofence
            let dataGeofenceSelect2 = [];
            for (let i = 0; i < this.siteGeofences.length; i++) {
                let geofenceName = this.siteGeofences[i].name;
                dataGeofenceSelect2.push({
                    id: this.siteGeofences[i].id,
                    text: geofenceName,
                    geofenceName: geofenceName
                });
            }
            // Ascending sort order Select2 data by text
            dataGeofenceSelect2 = _.orderBy(
                dataGeofenceSelect2,
                [geofence => geofence.text.toLowerCase()],
                ["text"],
                ["asc"]
            );
            // Init geofences select2
            commonVueHelper.destroySelect2(
                $("#newNotificationGeofenceSelect2")
            );

            $("#newNotificationGeofenceSelect2")
                .select2({
                    placeholder: i18n.t(
                        "geo_notif_notification_selectGeofence"
                    ),
                    width: "100%",
                    data: dataGeofenceSelect2,
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        },
                        searching: function () {
                            return i18n.t("common_searchDots");
                        }
                    }
                })
                .val(this.vGeofence)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vGeofence = $(this).val();
                });
        },

        initConditionSelect2() {
            var self = this;
            // Init Select2 data of geofence
            let dataConditionSelect2 = [];
            dataConditionSelect2.push({
                id: "IN",
                text: i18n.t("geo_notif_notificationTriggerConditionEnter"),
                name: i18n.t("geo_notif_notificationTriggerConditionEnter")
            });
            dataConditionSelect2.push({
                id: "OUT",
                text: i18n.t("geo_notif_notificationTriggerConditionExit"),
                name: i18n.t("geo_notif_notificationTriggerConditionExit")
            });
            dataConditionSelect2.push({
                id: "THRESHOLD",
                text: i18n.t("geo_notif_notificationTriggerConditionThreshold"),
                name: i18n.t("geo_notif_notificationTriggerConditionThreshold")
            });
            // Init condition select2
            commonVueHelper.destroySelect2(
                $("#newNotificationConditionSelect2")
            );

            $("#newNotificationConditionSelect2")
                .select2({
                    placeholder: i18n.t(
                        "geo_notif_notification_selectCondition"
                    ),
                    width: "100%",
                    data: dataConditionSelect2,
                    minimumResultsForSearch: -1
                })
                .val(this.vCondition)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vCondition = $(this).val();
                });
        },

        initStartDateTimePicker() {
            let selector = $("#notificationStartTimeDatePicker");
            let startDate = commonVueHelper.getToDayPlusXmin(5);
            commonVueHelper.initDateTimePicker(
                selector,
                "top-right",
                this.vStartTime,
                startDate,
                null
            );
            selector.on("changeDate", event => {
                this.vStartTime = new Date(event.date.valueOf());
                if (this.vEndTime) {
                    this.isDateSelected = true;
                } else {
                    this.isDateSelected = false;
                    this.errorDateMessage = i18n.t('error_endDateRequired');
                }
                if (this.vEndTime && this.vStartTime >= this.vEndTime) {
                    this.isCorrectEndDateTime = false;
                } else {
                    this.isCorrectEndDateTime = true;
                }
            });
            selector.on("focusout", event => {
                if (!selector.val()) {
                    this.vStartTime = null;
                    this.isCorrectEndDateTime = true;
                    this.isDateSelected = false;
                    this.errorDateMessage = i18n.t('error_startDateRequired');
                    if (!this.vEndTime) {
                        this.errorDateMessage = i18n.t('error_dateRequired');
                    }
                }
            });
        },

        initEndDateTimePicker() {
            let selector = $("#notificationEndTimeDatePicker");
            let startDate = commonVueHelper.getToDayPlusXmin(5);
            commonVueHelper.initDateTimePicker(
                selector,
                "top-right",
                this.vEndTime,
                startDate,
                null
            );
            selector.on("changeDate", event => {
                this.vEndTime = new Date(event.date.valueOf());
                if (this.vStartTime) {
                    this.isDateSelected = true;
                } else {
                    this.isDateSelected = false;
                    this.errorDateMessage = i18n.t('error_startDateRequired');
                }

                if (this.vStartTime && this.vEndTime <= this.vStartTime) {
                    this.isCorrectEndDateTime = false;
                } else {
                    this.isCorrectEndDateTime = true;
                }
            });
            selector.on("focusout", event => {
                if (!selector.val()) {
                    this.vEndTime = null;
                    this.errorDateMessage = i18n.t('error_endDateRequired');
                    this.isCorrectEndDateTime = true;
                    this.isDateSelected = false;
                    if (!this.vStartTime) {
                        this.errorDateMessage = i18n.t('error_dateRequired');
                    }
                }
            });
        },

        destroyDatePicker() {
            $("#notificationStartTimeDatePicker").datepicker('destroy');
            $("#notificationEndTimeDatePicker").datepicker('destroy');
        },

        enableTriggerAnythingSelect2() {
            $("#newNotificationAssetSelect2").attr("disabled", "true");
            $("#newNotificationAssetCategorySelect2").attr("disabled", "true");
            this.isCorrectAssetSelected = true;
            this.isCorrectAssetCategorySelected = true;
        },

        enableTriggerAssetCategorySelect2() {
            $("#newNotificationAssetSelect2").attr("disabled", "true");
            $("#newNotificationAssetCategorySelect2").removeAttr("disabled");
            this.isCorrectAssetSelected = true;
            if (this.vAssetCategories.length === 0) {
                this.isCorrectAssetCategorySelected = false;
            } else {
                this.isCorrectAssetCategorySelected = true;
            }
        },

        enableTriggerAssetSelect2() {
            $("#newNotificationAssetCategorySelect2").attr("disabled", "true");
            $("#newNotificationAssetSelect2").removeAttr("disabled");
            this.isCorrectAssetCategorySelected = true;
            if (this.vAssets.length == 0) {
                this.isCorrectAssetSelected = false;
            } else {
                this.isCorrectAssetSelected = true;
            }
        },

        disableRoleSelect2() {
            $("#newNotificationRoleSelect2").attr("disabled", "true");
            this.isCorrectRoleSelected = true;
        },

        disableUserSelect2() {
            $("#newNotificationOnlyUsersSelect2").attr("disabled", "true");
            this.isCorrectUserSelected = true;
        },

        enableRoleSelect2() {
            $("#newNotificationRoleSelect2").removeAttr("disabled");
            if (this.vRoles.length == 0) {
                this.isCorrectRoleSelected = false;
            } else {
                this.isCorrectRoleSelected = true;
            }
        },
        enableUserSelect2() {
            $("#newNotificationOnlyUsersSelect2").removeAttr("disabled");
            if (this.vUsers.length == 0) {
                this.isCorrectUserSelected = false;
            } else {
                this.isCorrectUserSelected = true;
            }
        },

        disableDateSelect2() {
            $("#notificationStartTimeDatePicker").attr("disabled", "true");
            $("#notificationStartTimeDatePicker").val(null);
            $("#notificationEndTimeDatePicker").attr("disabled", "true");
            $("#notificationEndTimeDatePicker").val(null);
            this.vStartTime = this.vEndTime = null;
            this.isCorrectEndDateTime = true;
            this.isDateSelected = true;
        },

        enableDateSelect2() {
            $("#notificationStartTimeDatePicker").removeAttr("disabled");
            $("#notificationEndTimeDatePicker").removeAttr("disabled");
            if (this.vStartTime && this.vEndTime) {
                this.isDateSelected = true;
            } else{
                this.isDateSelected = false;
                this.errorDateMessage = i18n.t('error_dateRequired');
                if(this.vStartTime && !this.vEndTime) {
                    this.errorDateMessage = i18n.t('error_endDateRequired');
                } else if(!this.vStartTime && this.vEndTime) {
                    this.errorDateMessage = i18n.t('error_startDateRequired');
                }
            }
        },

        newNotificationStatusChange() {
            if (this.statusActive) {
                this.statusMessage = i18n.t(
                    "geo_notif_notificationStatusActive"
                );
            } else {
                this.statusMessage = i18n.t(
                    "geo_notif_notificationStatusInactive"
                );
            }
        },

        // Function is executed when user check the webhook checkbox
        onCheckWebhook(event) {
            console.log("Component(newGeonotification)::onCheckWebHook() - called");
            if(event.target.checked) {
                this.vWebhookIsChecked = true;
            } else {
                this.vWebhookIsChecked = false;
            }
        },

        // Function which permits to add new header
        onClickAddNewHeader() {
            console.log("Component(newGeonotification)::onClickAddNewHeader() - called");
            var headers = $("#webhookHeaders");
            var newId = "header" + (headers.children().length + 1);
            var html = `<div id="${newId}" class="row kt-margin-b-20">
                            <div class="input-group col-lg-5">
                                <input type="text" name="key" class="form-control" />
                            </div>
                            <div class="input-group col-lg-5">
                                <input type="text" name="value" class="form-control" />
                            </div>
                            <div onclick="$('#${newId}').remove();" class="col-lg-2">
                                <a href="javascript:;" class="btn btn-danger btn-icon">
                                    <i class="la la-close"></i>
                                </a>
                            </div>
                        </div>`;
            headers.append(html);

        },

        // Get the webhook headers
        getWebhookHeaders() {
            // Add the value of webhook
            var webhookHeaders = [];
            var headers = $("#webhookHeaders").children('div');
            for (var header of headers) {
                // Get key and value
                var inputs = $("#" + header.id).find('input');
                var content = {};
                for (var input of inputs) {
                    if (input.name === "key" && input.value !== "") {
                        content.key = input.value;
                    }
                    if (input.name === "value" && input.value !== "") {
                        content.value = input.value;
                    }
                }
                // Verify if header is correct before adding
                if (content.hasOwnProperty('key') && content.hasOwnProperty('value')) {
                    webhookHeaders.push(content);
                }
            }
            return webhookHeaders;
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(newGeonotification)::onCancelButton() - called");
            this.$router.push({
                name: "geonotifications",
                params: { fromAction: "cancelButton" }
            });
        },

        // Function called when user click on the "Create notification" button
        onCreateButton() {
            console.log("Component(newGeonotification)::onCreateButton() - called");

            // Prepare final data
            const data = {
                siteId: this.siteId,
                name: this.vName,
                triggerCondition: this.vCondition,
                geofenceId: this.vGeofence,
                severity: this.vSeverity,
                isActive: this.statusActive,
                createAnother: this.createAnother
            };
            // Built the data body items
            if (this.vDateTime == "always") {
                data.startTime = null;
                data.endTime = null;
            } else {
                if (this.vStartTime) {
                    data.startTime = this.vStartTime.toISOString();
                }
                if (this.vEndTime) {
                    data.endTime = this.vEndTime.toISOString();
                }
            }
            // Check webhook is selected and get its id
            var webhook = _.find(this.vNotificationTypesList, {name:"WEBHOOK"});
            var notificationTypes = [];
            this.vNotificationTypes.forEach(notificationType => {
                if (notificationType === webhook.id) {
                    notificationTypes.push({
                        id: notificationType,
                        values: {
                            url: this.vWebhookUrl,
                            headers: this.getWebhookHeaders()
                        }
                    });
                } else {
                    notificationTypes.push({ id: notificationType });
                }
            });
            data.notificationTypes = notificationTypes;

            if (this.vTrigger == "anything") {
                data.isTriggerAll = true;
                this.vAssets = [];
                this.vAssetCategories = [];
            } else if (this.vTrigger == "asset") {
                data.isTriggerAll = false;
                this.vAssetCategories = [];
            } else {
                data.isTriggerAll = false;
                this.vAssets = [];
            }
            data.assets = this.vAssets;
            data.assetCategories = this.vAssetCategories;

            data.isAllUsers = (this.vWhoToAlert === "all" ? true : false);
            data.roles = (this.vWhoToAlert === "roles" ? this.vRoles : []);
            data.users = (this.vWhoToAlert === "users" ? this.vUsers : []);

            // Add in data the value of threshold when the condition is Threshold
            if (this.vCondition === "THRESHOLD") {
                data.assetThreshold = this.vThresholdNumber;
            }
            data.roles = this.vRoles;
            data.users = this.vUsers;
            this.createGeonotification(data);
        },

        isEmptyForm() {
            // Return true if all control form are empty
            let isEmptyForm = true;
            if (
                this.vName &&
                this.vGeofence &&
                this.vNotificationTypes &&
                this.vCondition &&
                this.vNotificationTypes
            ) {
                isEmptyForm = false;
            }
            return isEmptyForm;
        },

        isIQMServerDisabled() {
            if (this.currentSite && this.currentSite.IQMConfiguration && this.currentSite.IQMConfiguration.url) {
                return false;
            }
            return true;
        },

        // Are there any selected assets not declared on IQM when IQM notification is checked?
        isIQMNotDeclaredAssets() {
            // Asset checked with some undeclared assets
            if (this.vTrigger == "asset" && this.iqmNotDeclaredAssetNames && this.iqmNotDeclaredAssetNames.length > 0) {
                // Verify if the IQM Alarm server is checked
                if (this.iqmNotificationTypeId) {
                    const isIqmNotificationChecked = this.vNotificationTypes.find(notifTypeId => notifTypeId === this.iqmNotificationTypeId);
                    if (isIqmNotificationChecked) {
                        return true;
                    }
                }
            }
            return false;
        },

        // Set list of selected asset names not declared in IQM
        setIQMNotDeclaredAssetNames() {
            var self = this;
            self.iqmNotDeclaredAssetNames = null;
            if (self.siteAssets && self.vAssets && self.vAssets.length > 0) {
                const iqmNotDeclaredAssets = _.filter(self.siteAssets, siteAsset => {
                    const assetIdFound = _.find(self.vAssets, assetId => assetId === siteAsset.id );
                    if (assetIdFound && siteAsset.IQMStatus !== "DECLARED") {
                        return true;
                    }
                });
                self.iqmNotDeclaredAssetNames = _.map(iqmNotDeclaredAssets, "name");
            }
        },

        // Display the warning modal to inform on non declared assets on IQM
        onIQMAssetsWarningModal() {
            console.log("Component(newGeonotification)::onIQMAssetsWarningModal() - called");
            const strAssets = (this.iqmNotDeclaredAssetNames ? this.iqmNotDeclaredAssetNames.join(", ") : "");
            this.iqmAssetWarning = i18n.t("geo_notif_IQMWarningAssetsNotDeclared", { assets: strAssets });
            $("#IQMAssetWarningModal").modal("show");
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "app-iqmassetwarningmodal": IQMAssetWarningModal
    },
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(
            this,
            "newGeonotification",
            "create",
            !this.isEmptyForm(),
            to,
            from,
            next
        );
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.period {
    margin-left: 5px;
}
.webhookBloc {
    margin-left:40px;
    margin-top: 20px;
    margin-bottom: -20px;
}
#header1 a:first-child {
    background: #f9f9fc;
    border: none;
    cursor: initial;
    pointer-events: none;
}
#header1 a i:first-child {
    color: #f9f9fc;
}
</style>
